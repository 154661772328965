// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-cost-calculator-tsx": () => import("./../../../src/templates/cost-calculator.tsx" /* webpackChunkName: "component---src-templates-cost-calculator-tsx" */),
  "component---src-templates-hempcrete-tsx": () => import("./../../../src/templates/hempcrete.tsx" /* webpackChunkName: "component---src-templates-hempcrete-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-pdp-tsx": () => import("./../../../src/templates/pdp.tsx" /* webpackChunkName: "component---src-templates-pdp-tsx" */),
  "component---src-templates-plp-tsx": () => import("./../../../src/templates/plp.tsx" /* webpackChunkName: "component---src-templates-plp-tsx" */),
  "component---src-templates-project-description-tsx": () => import("./../../../src/templates/project-description.tsx" /* webpackChunkName: "component---src-templates-project-description-tsx" */),
  "component---src-templates-projects-list-tsx": () => import("./../../../src/templates/projects-list.tsx" /* webpackChunkName: "component---src-templates-projects-list-tsx" */),
  "component---src-templates-service-description-tsx": () => import("./../../../src/templates/service-description.tsx" /* webpackChunkName: "component---src-templates-service-description-tsx" */),
  "component---src-templates-services-list-tsx": () => import("./../../../src/templates/services-list.tsx" /* webpackChunkName: "component---src-templates-services-list-tsx" */)
}

